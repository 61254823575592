<div class="prizes-wrapper overflow-hidden" id="prizes">


  <div class="title-wrapper title-wrapper-prizes  container">
    <h1 class="title">Nyeremények</h1>
  </div>

  <div class="prizes-wrapper-loctite">
    <div class="container">
      <div class="flex flex-col md:flex-row max-w-[1260px] justify-center mx-auto">

        <div class="prize-main text-center lg:translate-x-[70px] pb-[20px] md:pb-0">
          <img class="translate-x-[45px] lg:translate-x-[65px]" src="/assets/img/home/prizes/prize_primary.png" alt="">
          <p class="text-[36px] xl:text-[50px] font-bold pt-[20px]">Főnyeremény</p>
          <p class="text-[22px] xl:text-[30px]">Utazás a Comói-tóhoz</p>
          <p class="text-[16px] xl:text-[23px] text-primary">2 fő részére, 600.000 Ft értékben</p>
        </div>
        <div class="prize-secondary text-center lg:translate-x-[-70px]">
          <img class="translate-x-[45px] lg:translate-x-[80px]" src="/assets/img/home/prizes/prize_secondary.png" alt="">
          <p class="text-[26px] xl:text-[40px] pt-[25px] font-bold">Heti nyeremény</p>
          <p class="text-[18px] xl:text-[25px]">KÄRCHER morzsaporszívó</p>
        </div>
      </div>
    </div>
  </div>

  <div class="info container font-gothamMX">
    <p>A nyeremények képe illusztráció.</p>
    <p>További részletek a Játékszabályzatban.</p>
  </div>
</div>
