import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {
  ApiLanguageService,
  AppConfig,
  AuthenticationService,
  DefaultErrorHandler,
  DefaultUserService,
  EclickCoreModule, ErrorInterceptor,
  HeaderInterceptor,
  initApiFactory,
  initAuthFactory,
  LanguageService,
  TokenInterceptor
} from '@eclick/core';
import {NgxScrollTopModule} from 'ngx-scrolltop';
import {AppInitService} from './core/services/app-init.service';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatNativeDateModule} from '@angular/material/core';
import {NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {SyossUserService} from './core/services/syoss-user.service';

const config: AppConfig = {
  apiUrl: 'https://loctite-toltsd-fel-a-kodot-es-nyerj.jatek.promoc.io/api/v1/',
  apiVersion: '1.0',
  supportedLanguages: ['hu'],
  refreshTokenAvailable: false,
  dbPrefix: 'loctite_toltsd_fel'
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'https://somat.henkelnyeremeny.hu/'
  },
  palette: {
    popup: {
      background: '#e9d582'
    },
    button: {
      background: '#ffffff',
      text: '#000'
    }
  },
  theme: 'block',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{buttons}}'
  },
  elements: {
    buttons: `
     <span id="cookieconsent:desc" class="cc-message">Az oldal alapműködéséhez szükséges sütik kezelése automatikusan történik. Ha engedélyezed a marketing célú sütik kezelését is az oldalon, kérlek kattints az elfogadom gombra! A sütikről részletesen a Süti (Cookie) szabályzatban olvashatsz.
        <a aria-label="learn more about cookies" role="button" tabindex="0" class="cc-link" href="https://www.henkelnyeremeny.hu/data/hu/documents/suti_szabalyzat_220201.pdf" rel="noopener noreferrer nofollow" target="_blank">Süti Szabályzat</a>
    </span>
    <div class="cc-compliance cc-highlight">
        <a aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny">Elutasítom</a>
        <a aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow">Elfogadom</a>
        <a aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny" style="position: absolute; padding:0 5px; top: 0; right: 0; border: none">X</a>
    </div>
    `,
  },
  content: {
    message: 'Az oldal alapműködéséhez szükséges sütik kezelése automatikusan történik. Ha engedélyezed a marketing célú sütik kezelését is az oldalon, kérlek kattints az elfogadom gombra! A sütikről részletesen a Süti (Cookie) szabályzatban olvashatsz.',
    link: 'Süti Szabályzat',
    href: 'https://www.henkelnyeremeny.hu/data/hu/documents/suti_szabalyzat_220201.pdf',
    allow: 'Elfogadom',
    deny: 'Elutasítom',
    policy: 'Cookie szabályzat'
  }
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}

export function ErrorInterceptorFactory(errorHandler: DefaultErrorHandler) {
  return (new ErrorInterceptor(config, errorHandler));
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgxScrollTopModule,
    EclickCoreModule.forRoot(config),
    MatNativeDateModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useFactory: ErrorInterceptorFactory, deps: [DefaultErrorHandler], multi: true
    },
    {
      provide: APP_INITIALIZER, useFactory: initAuthFactory, deps: [AuthenticationService], multi: true
    },
    {
      provide: APP_INITIALIZER, useFactory: initApiFactory, deps: [AppInitService, ApiLanguageService, LanguageService], multi: true
    },
    {
      provide: DefaultUserService, useExisting: SyossUserService
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
