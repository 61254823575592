<div class="my-codes-wrapper overflow-hidden">
  <div class="title-wrapper container text-right">
    <h1 class="title">Feltöltéseim</h1>
  </div>
  <div class="container flex">
    <div class="lg:w-1/2 relative min-h-[500px]">
      <div class="illustration"></div>
    </div>
    <div class="lg:w-1/2">
      <div class="container">
        <div class="uploaded-response-wrapper col-md-7" *ngIf="uploadResponse">
          <!--  <div class="uploaded-response-wrapper">-->
          <!--    <div class="uploaded-response">-->
          <!--      <div>-->
          <!--        <h2>Gratulálunk nyertél, nyereményed:</h2>-->
          <!--        <p>nyeremény</p>-->
          <!--        <p>Hamarosan felvesszük veled a kapcsolatot.</p>-->
          <!--        <h2>Köszönjük szépen a feltöltést, sajnos nem volt szerencséd.</h2>-->
          <!--        <p>Várunk vissza!</p>-->
          <!--      </div>-->
          <!--    </div>-->
          <div class="uploaded-response">
            <div *ngIf="uploadResponse.win_status === true else noWin">
              <!--      <div>-->
              <h2>Gratulálunk nyertél, nyereményed:</h2>
              <p>{{ uploadResponse.prize.name }}</p>
              <!--        <p>blabla</p>-->
              <p>Hamarosan felvesszük veled a kapcsolatot.</p>
            </div>
            <div class="button-container">
              <button class="main-btn" (click)="close()">
                Bezárás
              </button>
            </div>
            <ng-template #noWin>
              <h2>Köszönjük szépen a feltöltést.</h2>
              <p>Már ezzel a kódfeltöltéssel is indulsz a fődíj sorsoláson.
                <br>
                Tölts fel minél több kódot és növeld a nyerési esélyed!
              </p>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="container table-wrapper">
        <div class="my-codes-table">
          <div class="table-heading table-row">
            <div class="column">
              <p class="band-blue small">Feltöltött kód</p>
            </div>
            <div class="column ">
              <p class="band-blue small">
                Feltöltés ideje
              </p>
            </div>

          </div>
          <div class="table-row codes" *ngFor="let code of codes">
            <div class="column">
              {{ code.code }}
            </div>
            <div class="column">
              {{ code.created_at | date:"yyyy-MM-dd" }}
            </div>
          </div>
          <div class="no-codes" *ngIf="codes.length === 0">
            Még nem töltöttél fel kódot.
          </div>
          <!--      <div class="table-row codes">-->
          <!--        <div class="column">-->
          <!--          dfgdfgdfg-->
          <!--        </div>-->
          <!--        <div class="column">-->
          <!--          dfgdfgdfg-->
          <!--        </div>-->
          <!--      </div>-->
        </div>
      </div>
    </div>

  </div>
