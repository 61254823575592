<footer>
  <div class="container flex flex-col lg:flex-row">

    <div class="logo">
      <a href="https://www.henkel.hu/nevjegy#pageID=637988" target="_blank"><img src="/assets/img/shared/footer_logo.svg" class="img-fluid"></a>
    </div>


    <div class="flex flex-col justify-center lg:items-start gap-4 text-center lg:text-left">
      <p class="font-bold text-lg">
        Kérdésed van a promócióval kapcsolatban? <a [routerLink]="['/kapcsolat']">Írj nekünk!</a>
      </p>

      <p>© COPYRIGHT 2024, HENKEL MAGYARORSZÁG KFT., MAGYARORSZÁG</p>
    </div>

    <nav>
      <ul class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <a [routerLink]="['/promocios-termekek']">
          <li>Promóciós termékek</li>
        </a>
        <a href="#" [routerLink]="['/rules']">
<!--        <a href="#" target="_blank">-->
          <li>Játékszabályzat</li>
        </a>
<!--        <a href="{{ documents[1].file_url }}" target="_blank">-->
        <a href="#" [routerLink]="['/cookie']">
          <li>Süti szabályzat</li>
        </a>
        <a href="#" [routerLink]="['/privacy']">
          <li>Adatvédelmi tájékoztató</li>
        </a>
        <a href="#" [routerLink]="['/terms']">
          <li>Használati feltételek</li>
        </a>

<!--        <a href="http://www.henkelnyeremeny.hu/downloads/adatkezelesi_tajekoztato_a_honlap_hasznalataval_kapcsolatban.pdf" target="_blank">-->
<!--          <li>Adatkezelési tájékoztató</li>-->
<!--        </a>-->
        <a [routerLink]="['/gyik']">
          <li>GYIK</li>
        </a>
        <a [routerLink]="['/kapcsolat']">
          <li>Kapcsolat</li>
        </a>
        <a [routerLink]="['/imprint']">
          <li>Névjegy</li>
        </a>
      </ul>
    </nav>
  </div>
</footer>
